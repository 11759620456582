<script setup lang="ts">
import { useClientHandle } from '@urql/vue';
import * as Yup from 'yup';

import {
  PortalContactAcceptTermsConditionsDocument as Document,
  type PortalContactAcceptTermsConditionsMutation as Mutation,
  type PortalContactSessionFragmentFragment,
  type PortalContactAcceptTermsConditionsMutationVariables as Variables,
} from '@/graphql/pp/generated/graphql';
import useUrqlUpdate from '~/hooks/useUrqlUpdate';
import Form2 from '@/ui/Form/Form2.vue';

const props = defineProps<{
  open: boolean,
  loading?: boolean,
  onLogout:() => void,
  onSuccess:(data: PortalContactSessionFragmentFragment) => void,
}>();

const { onSubmitUrql, fetching, error } = useUrqlUpdate<Mutation, Variables>(
  useClientHandle(),
  Document,
  'portalContactAcceptTermsConditions',
);

const onSubmit = () => onSubmitUrql({}, {
  onError: () => {},
  onSuccess: (data) => {
    props.onSuccess(data.portalContactAcceptTermsConditions as PortalContactSessionFragmentFragment);
  },
});

interface FormValues {
  accept: boolean
}

const schema = Yup.object<FormValues>({
  accept: Yup.boolean().nullable()
    .oneOf([true], 'Please accept Terms of Service')
    .required('Please accept Terms of Service'),
});

const state = ref<FormValues>({ accept: false });

</script>
<template>
  <UModal
    :model-value="open"
    :prevent-close="true"
    :ui="{
      width: 'w-full w-screen-xl sm:max-w-3xl',
      container: 'max-h-[80vh] sm:max-h-screen',
      background: 'bg-transparent dark:bg-transparent',
    }"
  >
    <UCard
      :ui="{
        divide: 'divide-y divide-gray-100 dark:divide-gray-800',
        base: 'sm:max-h-[80vh] h-screen flex flex-col overflow-auto',
        body: {
          // eslint-disable-next-line vue/max-len
          base: 'flex-1 overflow-y-scroll px-4 mx-0.5 scrollbar-thin scrollbar-thumb-gg-primary-600 scrollbar-track-gg-primary-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full',
        },
      }"
    >
      <template #header>
        <h1 class="text-center text-lg font-semibold leading-6 text-gg-primary-600 dark:text-white">
          <SvgGradGuard class="h-6 mx-auto mb-4" />
          Terms of Service
        </h1>
        <UiErrorAlert v-if="error && !fetching" :error="error" class="mt-4" />
      </template>
      <div class="flex flex-col">
        <div class="flex-1 overflow-y-scroll">
          <div class="prose prose-h1:text-3xl prose-h2:text-xl mx-auto">
            <TermConditionsText />
          </div>
        </div>
      </div>
      <template #footer>
        <Form2
          :on-success="onSubmit"
          :state="state"
          :schema="schema"
        >
          <div class="flex justify-end mb-5">
            <UFormGroup required name="accept" data-cy="accept-terms-service-group">
              <UCheckbox
                v-model="state.accept"
                :disabled="fetching || loading"
                label="Accept Terms of Service"
                data-cy="accept-terms-service-checkbox"
              />
            </UFormGroup>
          </div>
          <div class="flex justify-between">
            <UButton
              :disabled="fetching || loading"
              :loading="loading"
              variant="link"
              @click="onLogout"
            >
              Logout
            </UButton>
            <UButton
              :disabled="fetching || loading"
              :loading="fetching"
              data-cy="terms-service-accept-button"
              type="submit"
            >
              Accept
            </UButton>
          </div>
        </Form2>
      </template>
    </UCard>
  </UModal>
</template>
