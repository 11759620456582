<script setup lang="ts">
import type { PartnerPortalContactSessionFragmentFragment } from '@/graphql/web/generated/graphql';
import type { ItemMenu } from '../../menu/types';
import { type MenuTheme, theme } from '@/ui/Menu/index';

const props = defineProps<{ partner: PartnerPortalContactSessionFragmentFragment }>();
const route = useRoute();

const partnerPortal = computed<ItemMenu[]>(() => [
  {
    icon: ['fal', 'gauge-high'],
    title: 'Dashboard',
    to: `/${props.partner.partner.uuid}`,
    key: `/${props.partner.partner.uuid}`,
  },
  {
    icon: ['fal', 'user'],
    title: 'Your Profile',
    to: '/profile',
    key: '/profile',
  },
  {
    icon: ['fal', 'circle-info'],
    title: 'Support',
    key: '/support',
    to: '/support',
  },
]);
const gradguard = computed<ItemMenu[]>(() => [
  {
    icon: ['fal', 'user-graduate'],
    title: 'Tuition',
    key: 'tuition',
    submenu: [
      {
        icon: ['fal', 'file-spreadsheet'],
        title: 'Policies',
        to: `/${props.partner.partner.uuid}/tuition/roster`,
        key: 'tuition/roster',
      },
      {
        icon: ['fal', 'list-check'],
        title: 'Refund Acknowledgment',
        to: `/${props.partner.partner.uuid}/tuition/refund`,
        key: 'tuition/refund',
      },
    ],
  },
  {
    icon: ['fal', 'house'],
    title: 'Renters',
    key: 'renters',
    submenu: [
      {
        icon: ['fal', 'file-spreadsheet'],
        title: 'Policies',
        to: `/${props.partner.partner.uuid}/renters/roster`,
        key: 'renters/roster',
      },
      /* {
        icon: ['fal', 'lightbulb-on'],
        title: 'Marketing Material',
        to: 'https://www.gradguardplaybook.com/',
        key: 'marketing-renters',
      }, */
    ],
  },
]);
const vendor: ItemMenu[] = [
  {
    icon: ['fal', 'building-columns'],
    title: 'CampusCoverage',
    key: 'CampusCoverage',
    to: 'https://campuscoverage.com/',
  },
];

const themeProfile: MenuTheme = {
  ...theme,
  class: /* tw */ 'text-sidebar-menu-text',
  li: {
    ...theme.li,
    class: [...theme.li.class, 'text-sidebar-menu-text'],
    focusable: {
      ...theme.li.focusable,
      selected: /* tw */ 'bg-sidebar-menu-selected-bg text-sidebar-menu-selected-text focus-visible:outline-sidebar-menu-focus',
      unselected: /* tw */ ' hover:bg-sidebar-menu-hover hover:text-sidebar-menu-hover-text focus-visible:outline-sidebar-menu-focus',
    },
  },
  subMenu: {
    ...theme.subMenu,
    button: {
      ...theme.subMenu.button,
      class: `focus-visible:outline-sidebar-menu-focus ${theme.subMenu.button.class}`,
      selected: /* tw */ 'bg-sidebar-menu-selected-bg text-sidebar-menu-selected-text',
      unselected: 'hover:bg-sidebar-menu-hover hover:text-sidebar-menu-hover-text',
      openUnSelected: 'bg-sidebar-menu-hover text-sidebar-menu-hover-text',
    },
  },
  subMenuItem: {
    ...theme.subMenuItem,
    focusable: {
      ...theme.subMenuItem.focusable,
      class: /* tw */ 'text-sm rounded-r-lg hover:text-sidebar-menu-hover-text hover:bg-sidebar-menu-hover focus-visible:outline-sidebar-menu-focus',
      selected: 'text-sidebar-menu-text',
    },
  },
};

</script>

<template>
  <LayoutSidebarMenuTitle title="Partner Portal" class="mb-4 pl-4 text-sidebar-text" />
  <Menu :items="partnerPortal" :selected="route.path" class="mb-5" :theme="themeProfile" />
  <LayoutSidebarMenuTitle title="Students" class="mb-4 pl-4 text-sidebar-text" />
  <Menu
    :items="gradguard"
    :selected="route.path"
    class="mb-5"
    :theme="themeProfile"
    data-cy="menu-students"
  >
    <template #submenu_item="{ subitem, baseClass, selected: isSelected }">
      <a
        v-if="
          subitem.key === 'marketing-tuition'
            || subitem.key === 'marketing-renters'
        "
        :class="['flex group', baseClass]"
        :href="subitem.to"
        target="blank"
      >
        <MenuSubItemContent
          :icons="subitem.icon"
          :title="subitem.title"
          :selected="isSelected"
        >
          <template #title>
            <div class="flex space-x-2 items-center">
              <span>
                {{ subitem.title }}
              </span>
              <span>
                <UiFaIcon class="w-3" :icon="['fal', 'arrow-up-right-from-square']" />
              </span>
            </div>
          </template>
        </MenuSubItemContent>
      </a>
      <MenuSubItemLink
        v-else
        :class="baseClass"
        :icons="subitem.icon"
        :to="subitem.to"
        :title="subitem.title"
        :selected="isSelected"
        :data-cy="`menu-${subitem.key}`"
      />
    </template>
  </Menu>
  <LayoutSidebarMenuTitle title="Schools" class="mb-4 pl-4 text-sidebar-text" />
  <Menu :items="vendor" :theme="themeProfile">
    <template #menu_base="{ item, baseClass }">
      <a :class="['flex group', baseClass]" :href="item.to" target="blank">
        <div>
          <UiFaIcon :icon="item.icon" />
        </div>
        <div class="flex space-x-2 items-center">
          <span>
            {{ item.title }}
          </span>
          <span>
            <UiFaIcon class="w-3" :icon="['fal', 'arrow-up-right-from-square']" />
          </span>
        </div>
      </a>
    </template>
  </Menu>
</template>
